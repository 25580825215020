import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div className={"footer_mention"}>Team = équipe / Match = correspondance</div>

        <div className={"footer_logo"}>
          <a id={"footer_logo_tictac"} href={"https://www.tictac.com/"} target={"_blank"}/>

          <div className={"footer_menu d-none d-lg-flex"}>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact</Link>
            <a href="/fr/fr/xp/choisistonmatch/file/Règlement.pdf?refresh" target={"_blank"}>Règlement du Jeu</a>
            <Link to="/informations-legales">Informations légales</Link>
            <a href="https://www.tictac.com/fr/fr/politique-en-matiere-de-cookies/" target={"_blank"}>Politique de Cookies</a>
            <a href="https://www.tictac.com/fr/fr/charte-de-protection-des-donnees-personnelles/" target={"_blank"}>Politique de Confidentialité</a>
          </div>

          <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france/"} target={"_blank"}/>
        </div>

        <div className={"footer_legal"}>
          Ferrero France Commerciale – SAS au capital de 13 174 330 euros – <br className={"d-lg-none"}/>
          RCS Rouen N°803 769 827 organise un jeu sans obligation d’achat du <br className={"d-lg-none"}/>
          9 octobre au 3 décembre 2023 inclus, réservé aux personnes <br className={"d-lg-none"}/>
          physiques majeures résidant <br className={"d-none d-lg-block"}/> en France Métropolitaine et Corse. <br className={"d-lg-none"}/>
          Voir détails et Règlement du Jeu complet <a href="/fr/fr/xp/choisistonmatch/file/Règlement.pdf?refresh" target={"_blank"}>ici</a>.
        </div>

      </div>

      
    </footer>
  );
};

export default Footer;
