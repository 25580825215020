import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Carousel} from 'react-bootstrap';
import PageHeader from '../_pageHeader';

const Home = () => {

  const [show, setShow] = useState(false);
  const [modalTeam, setModalTeam] = useState(0);
  const handleModalShow = (team) => {
    setModalTeam(team);
    setShow(true);
  }

  const createCarouselTeam = () => {

    let items         = [];

    for (let i = 0; i <= 2; i++) {

      items.push(
          <Carousel.Item key={"TeamItem" + i}>

            <div className={"carousel_team_item_wrapper"} id={"carousel_team_item_wrapper" + (i + 1)}>
              <a href={"#0"} onClick={() => handleModalShow(i)}>En savoir plus</a>
            </div>

          </Carousel.Item>
      );
    }

    return (
        <>
          <div id={"team_carousel_wrapper"}>
            <Carousel controls={true} interval={3000} indicators={true}>
              {items}
            </Carousel>
          </div>
        </>
    );
  };

  return (
    <>
      <PageHeader>
        <Link to={"/quiz"} className={"cta small"}>Je participe</Link>
      </PageHeader>
      <div id={"homeStrate1"} className={"strate"}>
        <h2 className={"picture"}>Des cadeaux qui matchent avec votre team</h2>
        {createCarouselTeam()}

        <div className={"desktop_team d-none d-lg-block"}>
          <a href={"#0"} onClick={() => handleModalShow(0)}>En savoir plus</a>
          <a href={"#0"} onClick={() => handleModalShow(1)}>En savoir plus</a>
          <a href={"#0"} onClick={() => handleModalShow(2)}>En savoir plus</a>
        </div>

        <div className={"text-center"}>
          <Link to={"/quiz"} className={"cta small"}>Je participe</Link>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} id={"modal_team"} centered>
        <a id={"modal_close"} href={"#0"} onClick={() => setShow(false)}>Retour</a>
        <Carousel controls={true} interval={null} indicators={false} activeIndex={modalTeam} onSelect={(index) => setModalTeam(index)}>
          <Carousel.Item key={"TeamPopupItem1"}>
            <div className={"carousel_teampopup_item_wrapper"} id={"carousel_teampopup_item_wrapper1"}/>
          </Carousel.Item>
          <Carousel.Item key={"TeamPopupItem2"}>
            <div className={"carousel_teampopup_item_wrapper"} id={"carousel_teampopup_item_wrapper2"}/>
          </Carousel.Item>
          <Carousel.Item key={"TeamPopupItem3"}>
            <div className={"carousel_teampopup_item_wrapper"} id={"carousel_teampopup_item_wrapper3"}/>
          </Carousel.Item>
        </Carousel>
      </Modal>


    </>
  );
};

export default Home;
